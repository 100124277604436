<template>
  <ul :class="$style.component">
    <li
      v-for="(listItem, index) in item.body"
      :key="`item-${index}`"
      :class="classes.item"
    >
      <base-bodytext :text="listItem.list_body" />
    </li>
  </ul>
</template>

<script>
import BaseBodytext from '@/components/base-bodytext'
import { mapState } from 'vuex'

export default {
  components: {
    BaseBodytext
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['currentLanguage']),
    classes() {
      return {
        item: [
          this.$style.item,
          this.currentLanguage != 'de' ? this.$style.otherLang : ''
        ]
      }
    }
  }
}
</script>

<style lang="scss" module>
.item {
  @extend %fs-small;

  min-height: calc(var(--blank-line) * 3.1);
  padding: calc(var(--blank-line) / 2) 0;
  border-bottom: solid 1px var(--black);
  transition: border-color var(--mlong) ease;

  &.otherLang {
    border-color: var(--white);
  }
}
</style>
